import './experienceBox.scss';

export default function ExperienceBox (props) {

    return (
        <a href={props.path} target='_blank' className="experienceBox" rel="noreferrer">
            <a className="image" target='_blank'>
                <img src={props.image} alt=""/>
            </a>
            
            <div className="info">
                {/* 
                snippet --> title 
                title --> Role
                subtitle --> dates
                */}
                <h3 className="title">{props.title}</h3>

                <h2 className="role" >{props.role}</h2>

                <h3 className="dates">{props.dates}</h3>

                <div className="tags">
                    <h5 style={{backgroundColor: props.color, color: props.textColor}}>{props.s1}</h5>
                    <h5 style={{backgroundColor: props.color, color: props.textColor}}>{props.s2}</h5>
                    <h5 style={{backgroundColor: props.color, color: props.textColor}}>{props.s3}</h5>
                </div>

                <p className="description">
                    {props.desc1} <br/>
                    {props.desc2} <br/>
                    {props.desc3}
                </p>

                <a href={props.path} className="e-link" target='_blank' rel="noreferrer">
                    Check it out &#8594;
                </a>
            </div>
        </a>
    );
}