import activestate from "./img/activestate.png"
import flexpa from "./img/flexpa.png"
import cisco from "./img/cisco.png"
import x from "./img/x.png"

export const experiences = [
    {
        title: "X (Twitter)",
        dates: "Jan - Apr 2024 | San Francisco, CA",
        role: "Software Engineer Intern | Search & Trends",
        tool1: "Python",
        tool2: "Mesos",
        tool3: "Aurora",
        desc1: "○ Deployed a novel AI Trends project to replace Twitter’s old keyword trends page from inception to launch in under 3 months on a 4-person engineering team",
        desc2: "○ Engineered a robust trends generation pipeline using Python that fetched and embedded 8M+ tweets using Kafka, performed hierarchical clustering, and utilized xAI’s LLM (Grok) to generate informative titles and summaries",
        color: "#000000",
        textColor: "#fff",
        path: "https://www.x.com/",
        image: x
    },
    {
        title: "Cisco Systems",
        dates: "May - Aug 2023 | Ottawa, ON",
        role: "Software Engineer Intern | SD-WAN vManage",
        tool1: "Python",
        tool2: "Java",
        tool3: "Zookeeper",
        desc1: "○ Led a critical project involving the automatic switchover of Application Visibility and Control (SDAVC) module to new vManage nodes during failure using Python and Java, ensuring 97% application uptime for 20K+ devices",
        desc2: "○ Spearheaded the development of a highly scalable Zookeeper-based solution to track SDAVC switch over state counts in large-scale distributed systems, successfully handling state change updates with sub-millisecond latency",
        color: "#00bceb",
        textColor: "#fff",
        path: "https://www.cisco.com/site/us/en/products/networking/wan/vmanage/index.html",
        image: cisco
    },
    {
        title: "Flexpa",
        dates: "Sept - Dec 2022 | San Francisco, CA",
        role: "Software Developer Intern | Core Product",
        tool1: "TypeScript",
        tool2: "React",
        tool3: "Datadog",
        desc1: "○ Planned and released a KYC (Know Your Customer) self-serve flow, enabling Flexpa to capture its first customer and revenue with a projected ROI of over $240,000 (215%) within the first year of deployment",
        desc2: "○ Streamlined the customer onboarding process by developing a new digital verification and notification system using incoming webhooks from Slack’s API, reducing time required for verification by 25+ minutes and wait times by 85%",
        color: "#0d201a",
        textColor: "#fff",
        path: "https://www.flexpa.com/",
        image: flexpa
    },
    {
        title: "ActiveState Software Inc.",
        dates: "Jan - April 2022 | Vancouver, BC",
        role: "Software Engineer Intern | Tools & Infastructure",
        tool1: "Golang",
        tool2: "AWS",
        tool3: "Kubernetes",
        desc1: "○ Spearheaded the end-to-end development of highly fault-tolerant cloud infrastructure to provide automated fail-overs and seamless management of AWS resources ensuring 99.99% system availability, using AWS ELB/CloudFormation",
        desc2: "○ Performed test-driven development by writing new unit tests for APIs on Golang, increasing code coverage by 23%",
        color: "#c22a1b",
        textColor: "#fff",
        path: "https://www.activestate.com/",
        image: activestate
    },
]