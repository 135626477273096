import "./experience.scss"
import { experiences } from "../../experienceData";
import Slider from "react-slick";
import ExperienceBox from "../experienceBox/experienceBox";

export default function Experience() {

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div className="experience" id="experience">
      <h1>Some Places I've Worked</h1>
      <Slider className="slider" {...settings}>
        {experiences.map((current) => (
          <div className="wrapper">
            <div className="container">
              <ExperienceBox 
                title={current.title} 
                dates={current.dates}
                role={current.role} 
                s1={current.tool1} 
                s2={current.tool2} 
                s3={current.tool3} 
                desc1={current.desc1} 
                desc2={current.desc2} 
                desc3={current.desc3} 
                color={current.color} 
                textColor={current.textColor} 
                image={current.image} 
                path={current.path} />
            </div>
          </div>
        ))}
      </Slider>

      {/* {experiences.map((current) => (
          <div className="wrapper">
            <div className="container">
              <ExperienceBox 
                title={current.title} 
                dates={current.dates}
                role={current.role} 
                s1={current.tool1} 
                s2={current.tool2} 
                s3={current.tool3} 
                desc1={current.desc1} 
                desc2={current.desc2} 
                desc3={current.desc3} 
                color={current.color} 
                image={current.image} 
                path={current.path} />
            </div>
          </div>
        ))} */}
    </div>


    // <div className="experience" id="experience">
    //     <img src={arrow}  className="arrow left" alt="" />
    //     <div className="slider">
    //       <div className="container">
    //         <div className="item">
    //         </div>
    //       </div>
    //     </div>
    //     <img src={arrow}  className="arrow right" alt="" />
    // </div>
  )
}
