import "./UnlockedPage.scss";
import pic1 from "../../img/pic1.jpg";
import pic2 from "../../img/pic2.jpg";
import pic3 from "../../img/pic3.jpg";
import pic4 from "../../img/pic4.jpg";

export default function UnlockedPage() {
  return (
    <div className="unlocked-page">
      <div className="pics">
        <figure class="gallery-item">
          <img class="gallery-img" src={pic1} alt="02/03/23" title="02/03/23" />
          <figcaption>02/03/23</figcaption>
        </figure>
        <figure class="gallery-item">
          <img class="gallery-img" src={pic2} alt="10/22/22" title="10/22/22" />
          <figcaption>10/22/22</figcaption>
        </figure>
      </div>
      <div class="letter">
        <div class="header">
          <p class="date">June 7, 2024</p>
          <h1>I LOVE YOU, DIYU</h1>
        </div>
        <div class="content">
          <p>
            hi diyu,
            its june 7th 11:43pm when I'm writing this. i just want to let you know 
            how i feel. i know i've stopped communicating with you as much but i want 
            to change that. i also know i've told you so many times i'll communicate 
            better and i haven't so i appologize for that. anyways i just wanted to say 
            that i love you so so so much. i'm so happy you're my girlfriend. even 
            though i wish i asked u out in a more romantic way, i'm still so happy 
            that you are my girlfriend. i know how much this means to you and i hope 
            this time around we can do it better. 
            <br/><br/>
            i know how much effort you've put 
            into making us work and i'm grateful for it. i'm going to make sure i put 
            in the same effort and be a better partner whether that be by letting you 
            know how I feel, making sure you are happy, giving you more undivided attention 
            or just doing small things for you like I used to. you really are my dream girl 
            and i wouldn't want anyone by my side more than you. i appreciate how you know 
            remember every little thing about me, things I don't even rmbr about myself! 
            <br/><br/>
            with that said, i also want you to tell me when i'm not doing something right 
            or if there is something that is upsetting u. i really think if we just tell each 
            other whats bothering us right away rather than letting it continue, we can both save 
            so much misunderstanding. i want to make sure i correct myself before it becomes a
            bigger deal than it needs to.
            <br/><br/>
            i also don't want you to think i'm not taking this 
            relationship seriously. we're getting into the age of when our parents ask us 
            about our future plans and i want to make sure i am a part of yours. before i
            leave for california, i want to meet your parents or at least let them know 
            that we are dating. i will also tell my parents. i want to date you to 
            marry you.
            <br/><br/>
            i really really love you and i can't wait to new memories with
            you. thank you for always being there for me, thank you for being paitent
            with me and thank you for loving me! i love you so much! 
          </p>
        </div>
        <div class="signature">
          <p>Forever yours, Het ❤️</p>
        </div>
        <p>
          <br/>
          ps i want to make a locked diary on this website where I can add pictures and written entires of us but its gonna take a while.
        </p>
      </div>
      <div className="pics">
        <figure class="gallery-item">
          <img class="gallery-img" src={pic3} alt="10/28/23" title="10/28/23" />
          <figcaption>10/28/23</figcaption>
        </figure>
        <figure class="gallery-item">
          <img class="gallery-img" src={pic4} alt="10/06/22" title="10/06/22" />
          <figcaption>10/06/22</figcaption>
        </figure>
      </div>
    </div>
  );
}
