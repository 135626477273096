import "./projects.scss"
import ProjectBox from "../projectBox/projectBox";
import ProjectList from "../projectList/projectList";
import { Blossom, Foodify, BlockBuddy, TrackDown } from "../../projectData";
import { useEffect, useState } from "react";

export default function Projects() {

  const [selected, setSelected] = useState("Blossom")
  const [data, setData] = useState([])

  const list = [
    {
      id: "Blossom",
      title: "Blossom"
    },
    {
      id: "TrackDown",
      title: "TrackDown"
    },
    {
      id: "Foodify",
      title: "Foodify"
    },
    {
      id: "Block Buddy",
      title: "Block Buddy"
    },
  ]

  useEffect(() => {

    switch(selected) {
      case "Blossom":
        setData(Blossom);
        break;
      case "TrackDown":
        setData(TrackDown);
        break;
      case "Foodify":
        setData(Foodify);
        break;
      case "Block Buddy":
        setData(BlockBuddy);
        break;
      default:
        setData(Blossom)
    }

  }, [selected])

  return (
    <div className='projects' id="projects">
      <h1>Some Projects I've Built</h1>
      <ul>
        {list.map(item=>(
          <ProjectList 
            title={item.title} 
            active={selected === item.id} 
            setSelected={setSelected}
            id={item.id}/>
        ))}
      </ul>
      <div className="container">
        <ProjectBox 
          snippet={data.snippet} 
          subTitle={data.subTitle}
          title={data.title} 
          s1={data.tool1} 
          s2={data.tool2} 
          s3={data.tool3} 
          desc1={data.desc1} 
          desc2={data.desc2} 
          desc3={data.desc3} 
          color={data.color} 
          textColor={data.textColor} 
          image={data.image} 
          path={data.path} />
      </div>
      
      <div class="wrapper">
        <a href="https://github.com/het1613" target="_blank" rel="noreferrer" className="button">More Projects</a>
      </div>

    </div>
  )
}
