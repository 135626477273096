import blossom from "./img/blossom.png"
import foodify from "./img/foodify.png"
import blockbuddy from "./img/blockbuddy.png"
import trackdown from "./img/TrackDown.png"

export const Blossom = {
    snippet: "Google Solution Challenge 2022",
    title: "Blossom",
    subTitle: "Achieved Top 3 Global out of 850+ teams ($15,000+ Cash Prize)",
    tool1: "Flutter (Dart)",
    tool2: "Firebase",
    tool3: "Google Cloud Platform APIs",
    desc1: "○ Built an Android and iOS app using Flutter to provide access to accurate and reliable menstrual education and resources for young girls in underprivileged nations",
    desc2: "○ Allows users to track period cycles, explore menstrual topics, find nearby health services & read related news/stories",
    color: "#EDD3D2",
    textColor: "#000",
    path: "https://github.com/het1613/Blossom",
    image: blossom
}

export const TrackDown = {
    snippet: "140+ Unique Users",
    title: "TrackDown",
    subTitle: "Easily convert your Spotify playlists to MP3 in minutes!",
    tool1: "Flask (Python)",
    tool2: "Spotify Web API",
    tool3: "HTML/CSS",
    desc1: "○ Hosted a Python/Flask server handling 75,000+ requests to the Spotify Web API for OAuth and data retrieval on Heroku",
    desc2: "○ Decreased download times by over 85% through enabling multicore processing to handle multiple downloads at once rather than individually",
    color: "#050a30",
    textColor: "#CAE8FF",
    path: "https://github.com/het1613/TrackDown",
    image: trackdown
}

export const Foodify = {
    snippet: "40+ Unique Users",
    title: "Foodify",
    subTitle: "Calories & Nutrient Tracker",
    tool1: "Flask (Python)",
    tool2: "SQLAlchemy",
    tool3: "Heroku",
    desc1: "○ Built and hosted a CRUD web app on Heroku with 40+ users to track daily nutrient intake and dietary goals",
    desc2: "○ Configured an SQLAlchemy database to store food logs and track user progress/goals",
    color: "#1DB954",
    textColor: "#000000",
    path: "https://github.com/het1613/Foodify",
    image: foodify
}

export const BlockBuddy = {
    snippet: "Available on Chrome Web Store",
    title: "Block Buddy",
    subTitle: "Social Media Blocker + Productivity Booster",
    tool1: "JavaScript",
    tool2: "Chrome Storage Sync",
    tool3: "HTML/CSS",
    desc1: "○ Developed and launched a web extension on the Chrome Web Store with 30+ users to increase productivity by blocking social media websites during study/work hours",
    desc2: "○ Integrated Chrome Storage Sync API within a JavaScript backend to save and sync user schedule and preferences across all devices",
    color: "#4040f0",
    textColor: "#fff",
    path: "https://github.com/het1613/Block-Buddy",
    image: blockbuddy
}