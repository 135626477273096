import useLocalStorage from "use-local-storage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Contact from "./components/contact/contact";
import Experience from "./components/experience/experience";
import Intro from "./components/intro/intro";
import Projects from "./components/projects/projects";
import PasswordPage from "./components/PasswordPage/PasswordPage";
import logo from "./img/HP_logo_black.png";

import "./App.scss";
import "./global.scss";
import "./components/topbar/topbar.scss";

function App() {
  const [theme, setTheme] = useLocalStorage("theme" ? "dark" : "light");

  const switchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
  };

  var flag = "";

  if (theme === "dark") {
    flag = "true";
  }

  return (
    <Router>
      <div className="app" data-theme={theme}>
        <Routes>
          <Route
            path="/"
            element={
              <>
                {/* SIDEBAR / NAVBAR */}
                <div className="topbar" id="topbar">
                  <div className="wrapper">
                    <div class="top">
                      <a href="#intro" className="selected">
                        <img src={logo} alt="" width="50" />
                      </a>
                    </div>

                    <div className="toggle">
                      <input
                        onClick={switchTheme}
                        className="l"
                        type="checkbox"
                        checked={flag}
                      />
                    </div>

                    <div className="middle">
                      <a href="#experience" className="selected logo exp">
                        Experience
                      </a>
                      <a href="#projects" className="selected logo proj">
                        Projects
                      </a>
                      <a href="#contact" className="selected logo con">
                        Contact
                      </a>
                      {/* <a href="/assets/Het Patel - Resume.pdf" target="_blank" rel="noreferrer" className="resume fill">Resume</a> */}
                    </div>
                  </div>
                </div>
                <div className="sections">
                  <Intro className="ignore" />
                  <Experience className="ignore" />
                  <Projects className="ignore" />
                  <Contact />
                </div>
              </>
            }
          />
          <Route path="/110424" element={<PasswordPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
