import "./intro.scss"
import { init } from 'ityped'
import { useEffect, useRef } from "react"

export default function Intro() {

  const textRef = useRef();

  useEffect(() => {
    init(textRef.current, { 
      showCursor: true, 
      backDelay: 1500,
      backSpeed: 60,
      strings: [
        "I am a software engineer.", 
        "Incoming @ NVIDIA", 
        "Previously @ X (Twitter)", 
        "Previously @ Cisco", 
      ] 
    })
  }, [])

  return (
    <div className='intro' id="intro">
      <div className="top">
        <div className="wrapper">
          <h4>Hi, my name is</h4>
          <h1>Het Patel</h1>
          <h2>.<span ref={textRef}></span></h2>
          <h3><a href="https://uwaterloo.ca/future-students/programs/systems-design-engineering" target="_blank" rel="noreferrer">Systems Design Engineering</a> @ <a href="https://uwaterloo.ca/" target="_blank" rel="noreferrer">UWaterloo</a></h3>
        </div>
      </div>
      <div className="downarrow">
        {/* <a href='#experience' className="arrow"><img src={arrow} alt="" width="35rem" /></a> */}
        <a href='#experience' className="arrow"><i class="fa fa-angle-down"></i></a>
      </div>
    </div>
  )
}
