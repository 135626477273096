import './projectBox.scss';

export default function ProjectBox (props) {

    return (
        <a href={props.path} target='_blank' className="projectBox" rel="noreferrer">
            <div className="info">
                <h3 className="snippet">{props.snippet}</h3>

                <h2 className="title">{props.title}</h2>
                {/* <h2 className="title" style={{color:props.color}}>{props.title}</h2> */}

                <h3 className="subTitle">{props.subTitle}</h3>

                <div className="tags">
                    <h5 style={{backgroundColor: props.color, color: props.textColor}}>{props.s1}</h5>
                    <h5 style={{backgroundColor: props.color, color: props.textColor}}>{props.s2}</h5>
                    <h5 style={{backgroundColor: props.color, color: props.textColor}}>{props.s3}</h5>
                </div>

                <p className="description">
                    {props.desc1} <br/>
                    {props.desc2} <br/>
                    {props.desc3}
                </p>

                <a href={props.path} className="e-link" target='_blank' rel="noreferrer">
                    Check it out &#8594;
                </a>
            </div>

            <a className="image" target='_blank'>
                <img src={props.image} alt=""/>
            </a>
        </a>
    );
}