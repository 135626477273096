import "./contact.scss";
import "font-awesome/css/font-awesome.min.css";
import { Link } from "react-router-dom";

export default function Contact() {
  return (
    <div className="contact" id="contact">
      <h1>Get In Touch</h1>

      <p>
        I'm always looking for new opportunities, so feel free to reach out!
      </p>

      <div className="social-buttons">
        <a
          href="mailto:het.patel1@uwaterloo.ca"
          target="_blank"
          className="social-buttons__button social-button social-button--envelope"
          aria-label="Envelope"
        >
          <i className="fa fa-envelope"></i>
        </a>
        <a
          href="https://www.linkedin.com/in/het1613/"
          target="_blank"
          className="social-buttons__button social-button social-button--linkedin"
          aria-label="LinkedIn"
        >
          <i className="fab fa-linkedin-in"></i>
        </a>
        <a
          href="https://github.com/het1613"
          target="_blank"
          className="social-buttons__button social-button social-button--github"
          aria-label="GitHub"
        >
          <i className="fab fa-github"></i>
        </a>
      </div>

      <h3>
        Designed and Developed by Het Patel &copy; 2022 &nbsp; | &nbsp;{" "}
        <Link to="/110424" className="secret-button">
          110424
        </Link>
        &nbsp; | &nbsp;{" "}
        <a
          href="https://het1613.github.io/portfolio-version-1/"
          target="_blank"
          rel="noreferrer"
        >
          Version 1
        </a>{" "}
        ↗
      </h3>
    </div>
  );
}
